import React, {useEffect} from 'react';
import {useLocation, Outlet, Navigate, useNavigate} from "react-router-dom";
import urls from "../Utils/urls";
import {useAuth} from "../Hooks/useAuth";
import {useTranslation} from "react-i18next";

const ProtectedRoute = () => {

    //επειδη το useAuth δεν προλαβαινει να ελενξει το localStorage και παρ ολο που εχω user τον δειχνει logged out κανω το ελεγχο εδω.
    //Το private route component δεν αφηνει καθολου τον χρηστη να μπει στη σελιδα μετα το refresh.
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const _user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const user = useAuth();

    return (
        _user
            ? <Outlet />
            : <Navigate to={`/${i18n.language}/${urls.login}`} state={{ from: location }} replace />

    );
};

export default ProtectedRoute;